import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import { Contact } from 'components/organisms/Contact'
import { BuildingLogCards } from 'components/organisms/BuildingLogCards'

const DziennikBudowyPage: React.FC<
  PageProps<Queries.DziennikBudowyPageQuery>
> = ({ data }) => {
  const PAGE = data.wpPage?.buildingLog
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const BUILDING_LOGS =
    data.allWpLogSingle.nodes.map((node) => ({
      image: {
        src: node.buildingLogSingle?.buildinglogSingleHeroImg?.localFile
          ?.childImageSharp?.gatsbyImageData!,
        alt: node.buildingLogSingle?.buildinglogSingleHeroImg?.altText!,
      },
      title: node.title!,
      text: node.buildingLogSingle?.buildinglogSingleExcerpt!,
      link: `/dziennik-budowy/${node.slug}`,
    })) || []

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <BuildingLogCards
        heading={PAGE.buildingLogHeroTitle!}
        cards={BUILDING_LOGS}
      />
      <Contact
        heading={PAGE.buildingLogFormTitle!}
        formTitle={PAGE.buildingLogFormFormTitle!}
        image={{
          src: PAGE.buildingLogFormImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.buildingLogFormImg?.altText!,
        }}
      />
    </Layout>
  )
}

export default DziennikBudowyPage

export const query = graphql`
  query DziennikBudowyPage {
    wpPage(slug: { regex: "/dziennik-budowy/" }) {
      title
      buildingLog {
        buildingLogHeroTitle
        buildingLogFormTitle
        buildingLogFormFormTitle
        buildingLogFormImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
    allWpLogSingle {
      nodes {
        buildingLogSingle {
          buildinglogSingleExcerpt
          buildinglogSingleHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
        title
        slug
      }
    }
  }
`
