import styled from 'styled-components'
import media from 'styles/media'

export const BuildingLogCardContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: 480px;

  ${media.xl.max} {
    height: 380px;
  }

  ${media.lg.max} {
    height: 280px;
  }
`

export const BuildingLogCardContent = styled.div`
  padding: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;

  ${media.sm.max} {
    gap: 24px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
`
