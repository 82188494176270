import React from 'react'

import { Text } from 'components/atoms/Typography'
import styled, { css } from 'styled-components'

const Navigation = styled.div`
  display: flex;
  gap: 8px;
`

const NavigationItem = styled.div<{ selected?: boolean }>`
  cursor: pointer;

  width: 40px;
  height: 40px;

  border-radius: 8px;

  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #3da544;

      p {
        color: white;
      }
    `}
`

const Elipsis = styled.span`
  color: ${({ theme }) => theme.colors.gray60};
  align-self: flex-end;
`

type PaginationProps = {
  numberOfPages: number
  currentPage: number
  onPageChange: (currentPage: number) => void
  showAtOnce?: number
  showAll?: boolean
}

type PaginationButtonProps = {
  page: number
  onClick: (currentPage: number) => void
  selected?: boolean
}
const PaginationButton: React.FC<PaginationButtonProps> = ({
  page,
  selected,
  onClick,
}) => (
  <NavigationItem
    selected={selected}
    onClick={selected ? undefined : () => onClick(page)}
  >
    <Text fontSize={14} fontWeight={300} fontFamily="roboto">
      {page}
    </Text>
  </NavigationItem>
)

export const Pagination: React.FC<PaginationProps> = ({
  numberOfPages,
  currentPage,
  onPageChange,
  showAll = false,
}) => {
  if (showAll) {
    return (
      <Navigation>
        {[...Array(numberOfPages)].map((_, index) => (
          <PaginationButton
            selected={currentPage === index + 1}
            onClick={onPageChange}
            page={index + 1}
          />
        ))}
      </Navigation>
    )
  }

  const isFirstButtonVisible = currentPage > 2
  const isLastButtonVisible = currentPage > numberOfPages - 2

  return (
    <Navigation>
      {isFirstButtonVisible && (
        <>
          <PaginationButton
            selected={currentPage === 1}
            onClick={onPageChange}
            page={1}
          />
          <Elipsis>...</Elipsis>
        </>
      )}

      {currentPage - 1 > 0 && (
        <PaginationButton onClick={onPageChange} page={currentPage - 1} />
      )}
      <PaginationButton selected onClick={onPageChange} page={currentPage} />
      {currentPage + 1 <= numberOfPages && (
        <PaginationButton onClick={onPageChange} page={currentPage + 1} />
      )}

      {!isLastButtonVisible && (
        <>
          <Elipsis>...</Elipsis>
          <PaginationButton
            selected={currentPage === numberOfPages}
            onClick={onPageChange}
            page={numberOfPages}
          />
        </>
      )}
    </Navigation>
  )
}
