import styled from 'styled-components'
import media from 'styles/media'

import { H1Light } from 'components/atoms/Typography'

export const Wrapper = styled.div`
  margin-top: 112px;

  ${H1Light} {
    padding: 72px 0;
  }

  ${media.sm.max} {
    margin-top: 58px;

    ${H1Light} {
      padding: 48px 24px;
    }
  }
`

export const InnerWrapper = styled.div``

export const BreadcrumbsContainer = styled.div`
  max-width: 1136px;
  margin: 0 auto;
  padding: 0 2rem;

  ${media.sm.max} {
    padding: 0;
  }
`

export const BuildingLogCardsOuterWrapper = styled.div`
  padding: 100px 120px;

  ${media.lg.max} {
    background-color: #f3f3f3;
    padding: 48px 24px;
  }
`

export const BuildingLogCardsInnerWrapper = styled.div`
  max-width: 1136px;

  width: fit-content;

  margin: 0px auto;

  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 64px;

  ${media.xxl.max} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.lg.max} {
    gap: 24px;
  }

  ${media.sm.max} {
    row-gap: 40px;
    grid-template-columns: repeat(1, 1fr);
  }
`

export const PaginationContainer = styled.div`
  max-width: 1136px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  gap: 16px;

  align-items: center;

  ${media.lg.max} {
    margin-top: 24px;
  }
`
