import React from 'react'
import { Link } from 'gatsby'

import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'
import { IconButton } from 'components/atoms/Button'
import { H3Bold, TextBody1 } from 'components/atoms/Typography'
import { LazyImage } from 'components/atoms/Image'
import { ImageType } from 'types/image'

import {
  BuildingLogCardContainer,
  ImageWrapper,
  BuildingLogCardContent,
  TextContainer,
} from './BuildingLogCard.styles'

export type BuildingLogCardType = {
  image: ImageType
  title: string
  text: string
  link: string
}

export const BuildingLogCard = ({
  image,
  title,
  text,
  link,
}: BuildingLogCardType) => {
  return (
    <BuildingLogCardContainer as={Link} to={link}>
      <ImageWrapper>
        <LazyImage src={image?.src} alt={image?.alt!} fill objectFit="cover" />
      </ImageWrapper>
      <BuildingLogCardContent>
        <TextContainer>
          <H3Bold dangerouslySetInnerHTML={{ __html: title }} />
          <TextBody1 dangerouslySetInnerHTML={{ __html: text }} />
        </TextContainer>
        <IconButton green>
          <ArrowRight />
        </IconButton>
      </BuildingLogCardContent>
    </BuildingLogCardContainer>
  )
}
