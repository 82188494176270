import React, { useState } from 'react'

import { H1Light, TextBody1 } from 'components/atoms/Typography'

import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import {
  BuildingLogCardType,
  BuildingLogCard,
} from 'components/modules/BuildingLogCard'

import { Pagination } from 'components/atoms/Pagination'

import {
  Wrapper,
  InnerWrapper,
  BreadcrumbsContainer,
  BuildingLogCardsOuterWrapper,
  BuildingLogCardsInnerWrapper,
  PaginationContainer,
} from './BuildingLogCards.styles'

type Props = {
  heading: string
  cards: BuildingLogCardType[]
}

export const BuildingLogCards: React.FC<Props> = ({ heading, cards }) => {
  const itemsPerPage = 2

  const [currentPage, setCurrentPage] = useState(1)
  const numberOfPages = Math.ceil(cards.length / itemsPerPage)

  const onPageChange = (pageNumber: number) => {
    const targetSection = document.getElementById('dziennik-budowy')
    if (targetSection) {
      const { top } = targetSection.getBoundingClientRect()
      window.scrollTo({
        top: top + window.pageYOffset - 40,
        behavior: 'smooth',
      })
    }
    setCurrentPage(pageNumber)
  }

  const pageCards = [
    ...cards.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ),
  ]

  return (
    <Wrapper>
      <BreadcrumbsContainer>
        <Breadcrumbs crumbs={[{ name: 'Dziennik budowy' }]} />
      </BreadcrumbsContainer>
      <H1Light
        as="h1"
        dangerouslySetInnerHTML={{ __html: heading }}
        align="center"
      />
      <InnerWrapper id="dziennik-budowy">
        <BuildingLogCardsOuterWrapper>
          <BuildingLogCardsInnerWrapper>
            {pageCards?.map(({ image, title, text, link }, index) => (
              <BuildingLogCard
                image={image}
                title={title}
                text={text}
                link={link}
                key={`${heading}-${index}`}
              />
            ))}
          </BuildingLogCardsInnerWrapper>
          <PaginationContainer>
            <TextBody1 color="gray60">Strona</TextBody1>
            <Pagination
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          </PaginationContainer>
        </BuildingLogCardsOuterWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}
